@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .nav-link {
    @apply flex lg:inline text-lemon py-5 px-5 lg:p-0 mx-7 hover:underline hover:text-lemon transition-all;
  }
  th,
  td {
    @apply p-0;
  }
  body {
    @apply text-white;
  }

  .icon-context-sidebar {
    @apply mx-1;
  }
  .sidebar-link {
    @apply my-2 p-2 rounded-md flex items-center text-green-aqua hover:text-neutral-900 hover:bg-green-aqua/70;
  }
}

@layer utilities {
  .nav-link:hover {
    text-shadow: 0px 0px 4px #f9ff01;
  }

  /* .logo {
    filter: drop-shadow(0 0 5px #0000004d);
  } */

  svg:hover {
    filter: drop-shadow(0 0 3px #00ebff);
  }

  .svg-pink:hover {
    filter: drop-shadow(0 0 3px #ff39b0);
  }

  .svg-lemon:hover {
    filter: drop-shadow(0 0 3px #f9ff01);
  }

  .text-outline-pink {
    -webkit-text-stroke: 1px #ff39b0;
    text-stroke: 1px #ff39b0;
  }
  .text-outline-blue {
    -webkit-text-stroke: 1px #00ffff;
    text-stroke: 1px #00ffff;
  }

  .text-outline-lemon {
    -webkit-text-stroke: 1px #f9ff01;
    text-stroke: 1px #f9ff01;
  }

  .text-outline-green {
    -webkit-text-stroke: 1px #6fff4a;
    text-stroke: 1px #6fff4a;
  }
}

span.milestone.vertical-timeline-element-date {
  font-weight: bold;
  font-size: 2rem !important;
  padding: 0 1.5rem;
  position: absolute;
  width: 100%;
  left: auto;
  right: 124%;
  text-align: right;
  float: left;
  top: 6px;
}

.vertical-timeline-element-content {
  background-color: transparent;
  margin-left: 40px;
  float: right;
  width: 44%;
}

.vertical-timeline-element-content p {
  font-weight: 400;
  margin: 0;
  max-width: 100%;
}

.vertical-timeline::before {
  width: 2px;
  height: 100%;
  left: 50%;
  background-image: repeating-linear-gradient(
    0deg,
    #071428,
    #071428 13px,
    transparent 13px,
    transparent 30px,
    #071428 20px
  );
}

.vertical-timeline-element-icon {
  width: 60px;
  height: 60px;
  left: 50%;
  margin-left: -30px;
}

@media only screen and (max-width: 768px) {
  span.milestone.vertical-timeline-element-date {
    padding: 0;
    left: initial;
    right: initial;
    text-align: initial;
    top: -25px;
  }
  .vertical-timeline-element-content {
    top: 25px;
    float: initial;
    width: 90%;
  }
  .vertical-timeline-element-content p {
    /* max-width: 100%; */
  }
  .vertical-timeline::before {
    left: 22px;
    /* left: 50%; */
  }
  .vertical-timeline-element-icon {
    width: 50px;
    height: 50px;
    left: 27px;
  }
}
